<template>
  <div>
    <b-card>
      <div class="row no-print">
        <div class="col-3">
          <b-form-input
            v-model="searchForm.date__gte"
            type="date"
          />
        </div>
        <div class="col-3">
          <b-form-input
            v-model="searchForm.date__lte"
            type="date"
          />
        </div>
        <div class="col">
          <button
            class="btn btn-primary"
            @click="fetchData"
          >Показать</button>
        </div>
      </div>
      <b-overlay :show="loading">
        <table class="mt-2 table table-sm table-striped">
          <tr>
            <th>#</th>
            <th>ФИО</th>
            <th>Кол-во</th>
            <th
              v-for="date in dates"
              :key="date"
              class="text-center"
            >{{ date }}</th>
          </tr>
          <tr
            v-for="(pp, idx) in Object.values(plan)"
            :key="idx"
          >
            <td>{{ idx + 1 }}</td>
            <td>
              <a
                href="javascript: null"
                @click="$router.push({name: 'driver-view', params: {id: pp.driver.id}})"
              >{{ `${pp.driver.last_name} ${pp.driver.first_name} ${pp.driver.middle_name}` }}</a>
            </td>
            <td>{{ Object.keys(pp.items).length }}</td>
            <td
              v-for="date in dates"
              :key="date"
              class="text-center"
              :class="{'bg-dark': pp.items[date] && pp.items[date].is_day !== 1}"
            >
              <template
                v-if="pp.items[date]"
              >
                <div class="d-flex justify-content-around align-items-center">
                  <a
                    href="javascript: null"
                    :class="{ 'text-white': !pp.items[date].is_day }"
                    @click="$router.push({name: 'cars-view', params: { id: pp.items[date].car_id }})"
                  >
                    {{ pp.items[date].car_number }}
                    <span class="on-print">
                      <br>
                      {{ pp.items[date].is_day === 1 ? 'День' : 'Ночь' }}
                    </span>
                  </a>
                  <div
                    class="cursor-pointer no-print"
                    @click="removePlan(pp.items[date].id)"
                  >
                    <feather-icon
                      class="text-danger"
                      icon="TrashIcon"
                    />
                  </div>
                </div>
              </template>
              <template
                v-else
              >-</template>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>{{ stats.total }}</td>
            <td
              v-for="date in dates"
              :key="date"
              class="text-center"
            >{{ statsDates[date] }}</td>
          </tr>
        </table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BOverlay,
  BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BOverlay,
    BFormInput,
  },
  data() {
    return {
      loading: false,
      searchForm: {
        date__gte: null,
        date__lte: null,
        driver_id__null: false,
      },
      plan: [],
      dates: [],
      exists_drivers: [],
      stats: {
        total: 0,
      },
      confirmMessage: 'Удалть запись?',
    }
  },
  computed: {
    statsDates() {
      return Object.fromEntries(Object.entries(this.stats).filter(([key]) => key !== 'total'))
    },
  },
  mounted() {
    this.searchForm.date__lte = moment().add(2, 'day').format('YYYY-MM-DD')
    this.searchForm.date__gte = moment().subtract(1, 'day').format('YYYY-MM-DD')
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.stats = { total: 0 }
      this.$axios.get(
        '/cars/plans', {
          params: {
            filter: this.searchForm, sort: 'driver', all: true, populate: 'car,driver',
          },
        },
      ).then(response => {
        this.plan = []
        this.dates = []
        this.exists_drivers = {}
        this.stats.total = response.data.data.length
        response.data.data.forEach((item, idx, arr) => {
          if (!Object.hasOwn(this.stats, item.date)) {
            this.stats[item.date] = 0
          }
          this.stats[item.date] += 1
          if (this.dates.indexOf(item.date) === -1) {
            this.dates.push(item.date)
          }
          if (!Object.hasOwn(this.exists_drivers, item.driver_id)) {
            this.plan.push({
              driver: item.driver,
              items: {},
            })
            this.exists_drivers[item.driver_id] = this.plan.length - 1
          }
          const driverIdx = this.exists_drivers[item.driver_id]
          this.plan[driverIdx].items[item.date] = {
            id: item.id,
            car_id: item.car_id,
            car_number: item.car.number,
            is_day: item.is_day,
          }
          if (idx >= arr.length - 1) {
            this.dates.sort((a, b) => {
              const ma = moment(a)
              const mb = moment(b)
              if (ma === mb) {
                return 0
              }
              return ma > mb ? 1 : -1
            })
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    removePlan(id) {
      this.$bvModal.msgBoxConfirm(this.confirmMessage).then(status => {
        if (status) {
          this.loading = true
          this.$axios.delete(`/cars/plans/${id}`).then(() => {
            this.fetchData()
          })
        }
      })
    },
  },
}
</script>
